.container {
  padding: 16px 12px;
  text-align: center;
}

@media (min-width: 768px) {
  .container {
    max-width: 960px;
    margin: 0 auto;
  }
}

.grid {
  display: grid;
  grid-template-columns: repeat(8, 40px);
  grid-template-rows: repeat(5, 40px);
  gap: 2px;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
}


.grid-item {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  border: 1px solid #000;
  font-size: 2rem;
}

@media (min-width: 500px) {
  .grid {
    grid-template-columns: repeat(8, 50px);
    grid-template-rows: repeat(5, 50px);
  }
  .grid-item {
    width: 50px;
    height: 50px;
  }
}